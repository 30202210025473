import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { debounce } from 'lodash';
import { WDWDLRQQAnalyticsLinkParams } from '@interfaces/cruise-listing-analytics-model';
import { ConfigService } from '@app/core/config.service';
import { HomepageAnalyticsService } from '@app/services/analytics/analytics.service';
import { HttpClient } from '@angular/common/http';
import { ENDPOINTS } from '@app/app.constants';
@Component({
    selector: 'pixie-homepage',
    templateUrl: './homepage.component.html',
    styleUrls: ['./homepage.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class HomepageComponent implements OnInit {

    id = '';
    title = '';
    ctas = '';
    siteId: string;
    baseUrl: string;
    utilsConfig;

    constructor(
        private configService: ConfigService,
        private analyticsService: HomepageAnalyticsService,
        private httpClient: HttpClient,
    ) { }

    ngOnInit() {
        this.initDLRQuickQuoteConfig();

        this.siteId = this.configService.getValue('siteId');
        this.orderElements();

        window.addEventListener('resize', debounce(() => {
            this.orderElements();
        }, 100));
    }

    /** Places elements in the correct order in the DOM so that keyboard navigation order matches */
    orderElements = () => {
        const homepageTop = document.getElementById('homepage-top');
        const qqContainer = document.getElementById('quick-quote-container');
        const pixieHero = document.getElementsByTagName('pixie-hero')[0];
        const windowSize = window.innerWidth;
        if (windowSize >= 1024) { // Desktop size
            homepageTop.prepend(qqContainer);
        } else { // Mobile size
            homepageTop.prepend(pixieHero);
        }
    }

    /**
     * Receives the analytics data from the respective triggered Event Emitter
     * @param analyticsData object with the values needed for Analytics Function.
     */
    analyticsTrackingEvent = (linkId: string, analyticsData?: WDWDLRQQAnalyticsLinkParams) => {
        this.analyticsService.trackLink(linkId, analyticsData);
    }

    /**
     * Make call to utils and d-scribe to get the configs and contents for QQ
     */
    public initDLRQuickQuoteConfig(): void {
        this.httpClient.get(
            `${ENDPOINTS.utilsConfig}`
        ).subscribe((config) => {
            this.utilsConfig = config;
            document.querySelector('body').classList.add('qq-ready');
        });
    }
}
